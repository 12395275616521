import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Become a Humainly Worker | Venezuela"/>
    <section id="about">
      <div className="container">
        <div className="t2-padding">
          <h1>Gana dinero etiquetando imágenes desde casa</h1>
          <h4>Become a Humainly Worker</h4>
        </div>
      </div>
    </section>

    <main className="container recruit mt-4">
      <section className="row">
        <div className="col-md-7 ">
          <div className="box-item">
            <div>
              <h3>Descripción</h3>
              <p>Humainly ayuda a los clientes a crear datos de entrenamiento usados en la Inteligencia Artificial
                Visual
                para reconocer imágenes automáticamente. Esto significa que recibimos muchas imágenes a las que luego
                les
                tenemos que agregar etiquetas manualmente. Gracias a esto, generamos muchos trabajos para las personas
                que estén dispuestas a ayudarnos a realizar ese trabajo de etiquetado manual. <b>Este trabajo les
                  permite
                  ganar un salario honesto, donde y cuando quiera.</b></p>
            </div>

            <div className="up">
              <h3>Ejemplos de tareas</h3>
              <ul>
                <li>Agregar una etiqueta simple a una imagen. Por ejemplo: ¿Es esta imagen un gato o un perro?</li>
                <li>Dibujando rectángulos ajustados alrededor de objetos. Ej: "Marca todos las caras visibles en la
                  foto"
                </li>
                <li>Delinear perfectamente los contornos de los objetos. Ej: Dibujar líneas precisas sobre los bordes de
                  los muebles.
                </li>
              </ul>
            </div>

            <div className="up">
              <h3>Requerimientos</h3>
              <ul>
                <li>Tener acceso a una computadora de escritorio/laptop con conexión a internet</li>
                <li>Ser mayor de 18 años</li>
                <li>Prestar atención a los detalles (¡la precisión de las etiquetas es mucho más importante que la
                  velocidad!)
                </li>
                <li>Disfrutar de este tipo de trabajo :)</li>
              </ul>
            </div>

            <div className="up">
              <h3>Pago</h3>
              <p>Puedes ganar fácilmente <b>1 USD por hora</b> (incluso más si trabajas más rápido). Calculamos cuántas
                imágenes
                deberías poder procesar por hora y lo convertimos en un número de recompensa por imagen. Muchas personas
                pueden hacerlo más rápido que eso, por lo que de hecho pueden ganar mucho más de 1 USD por hora. <b>Cada
                  dos
                  semanas se paga</b> por el trabajo realizado.</p>
            </div>

            <div className="up text-center">
              <a href="https://app.humainly.com/worker/register?country=VE"
                 className="button button--red button--rounded" target="_blank" rel="noopener noreferrer">COMIENZA YA</a>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <div className="box-item">
            <h3>Proceso de aplicación</h3>
            <ol>
              <li className="up-10">Haz click en el botón de <b>"Aplicar ahora"</b> para ir a un formulario de
                aplicación y rellena los espacios
              </li>
              <li className="up-10">Después de completar tu información básica, se te pedirá que realices una pequeña
                prueba de etiquetado de imágenes
              </li>
              <li className="up-10">Las personas que se desempeñen bien en esta prueba son invitadas a comenzar la
                capacitación para un proyecto real
              </li>
              <li className="up-10">Para cada proyecto, hay una fase de capacitación para entender de qué se trata la
                tarea. No te preocupes, haremos todo lo posible para ayudarte
              </li>
            </ol>
            <div className="up-m text-center">
              <a href="https://app.humainly.com/worker/register?country=VE" className="button button--red button--rounded" target="_blank" rel="noopener noreferrer">APLICAR AHORA</a>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default Page
